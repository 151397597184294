import _pathPlatform from "path-platform";
import _process from "process";
var exports = {};
var process = _process;
var pathPlatform = _pathPlatform;

exports = function (cwd, opts) {
  if (cwd === undefined) cwd = process.cwd();
  if (!opts) opts = {};
  var platform = opts.platform || process.platform;
  var isWindows = /^win/.test(platform);
  var path = isWindows ? pathPlatform.win32 : pathPlatform;
  var normalize = !isWindows ? path.normalize : path.normalize("c:") === "c:." ? fixNormalize(path.normalize) : path.normalize;
  var sep = isWindows ? /[\\\/]/ : "/";
  var init = isWindows ? "" : "/";

  var join = function (x, y) {
    var ps = [x, y].filter(function (p) {
      return p && typeof p === "string";
    });
    return normalize(ps.join(isWindows ? "\\" : "/"));
  };

  var res = normalize(cwd).split(sep).reduce(function (acc, dir, ix) {
    return acc.concat(join(acc[ix], dir));
  }, [init]).slice(1).reverse();
  if (res[0] === res[1]) return [res[0]];

  if (isWindows && /^\\/.test(cwd)) {
    return res.slice(0, -1).map(function (d) {
      var ch = d.charAt(0);
      return ch === "\\" ? d : ch === "." ? "\\" + d.slice(1) : "\\" + d;
    });
  }

  return res;

  function fixNormalize(fn) {
    return function (p) {
      return fn(p).replace(/:\.$/, ":");
    };
  }
};

export default exports;